import React from 'react';
import Layout from 'components/layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hero from 'components/hero-background';
import Title from 'components/title';
import Subtitle from 'components/text/subtitle/subtitle.css';
import styled from 'styled-components';
import { Link } from 'gatsby';

const GoHomeText = styled(Subtitle)`
  margin-top: 2rem;
  align-self: flex-end;
  color: inherit;
`;

const NotFound = ({ data }) => (
  <Layout>
    <Hero background={data.homeJson.hero.backgroundImage}>
      <Title as="h2" size="large" color={'#fff'}>
        Page not found.
      </Title>
      <GoHomeText as={'p'} color={'#fff'}>
        <Link to="/">Go Home</Link>
      </GoHomeText>
    </Hero>
  </Layout>
);

export default NotFound;

NotFound.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query homepageQuery {
    homeJson {
      hero {
        backgroundImage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
